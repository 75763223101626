import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { OriginCountryService } from 'src/app/@Core/service/origin-country.service';
import { WashingInstructionService } from 'src/app/@Core/service/washing-instruction.service';
import { WarningSymbol } from '../dto/WarningSymbol';
import { WashingInstruction } from '../dto/WashingInstruction';
import { ChineseLabelDialogComponent } from './chinese-label-dialog/chinese-label-dialog.component';
import { FabricOptionsDialogComponent } from './fabric-options-dialog/fabric-options-dialog.component';
import { OriginCountryDialogComponent } from './origin-country-dialog/origin-country-dialog.component';
import { RussianTypeDialogComponent } from './russian-type-dialog/russian-type-dialog.component';
import { SymbolDialogComponent } from './symbol-dialog/symbol-dialog.component';
import { WashingInstructionsDialogComponent } from './washing-instructions-dialog/washing-instructions-dialog.component';
import {DialogService} from 'primeng/dynamicdialog';
import { WarningLabelService } from 'src/app/@Core/service/warning-label.service';

@Component({
  selector: 'app-create-washing-instructions',
  templateUrl: './create-washing-instructions.component.html',
  styleUrls: ['./create-washing-instructions.component.scss']
})
export class CreateWashingInstructionsComponent implements OnInit {

  @ViewChild('fabricDiv')
  fabricDiv!: ElementRef;

  @ViewChild('instructionDiv')
  instructionDiv!: ElementRef;


  fabricColors = [
    {
      title: 'White',
      value: '#ffffff'
    },
    {
      title: 'Black',
      value: '#000000'
    },
    {
      title: 'Sand',
      value: '#af9778'
    }
  ]

  originLanguages = ['gb', 'fr', 'pt', 'gr', 'ua', 'il', 'sa', 'id']

  fabricLanguages = [
    'GB', 'DE', 'FR', 'ES', 'IT', 'PT', 'GR', 'UA', 'IL', 'SA', 'ID'
  ]

  instructionLanguages = [
    'GB', 'DE', 'FR', 'ES', 'IT', 'PT', 'GR'
  ]

  poNumber: string = '';
  serial: string = '';

  bgColor: string = '#fff';
  textColor: string = '#000';

  origin: string = '';
  originValue: string = '';
  originObj: any = {
    value: {
      gb: '',
      ru: '',
      ch: ''
    }
  }

  chineseOrigin = '';
  russianOrigin = '';
  isOriginSelected = false;

  isFabricSelected = false
  isInstructionsSelected = false
  instructions: any[] = [];

  selectedSymbols = [
    [], [], [], [], []
  ];

  symbolsShown: WarningSymbol[] = [];

  fabricOptions: ({ productPart: any, features: ({ name: any, ratio: number })[] })[] = [];
  rusFabricOptions: ({ productPart: any, features: ({ name: any, ratio: number })[] })[] = [];
  chineseFabricOptions: ({ productPart: any, features: ({ name: any, ratio: number })[] })[] = [];

  chineseSizes: any[] = []

  warningLabels = [
    /*{
      name: 'Deselect',
      filePath: '',
      text: ''
    },
    {
      name: 'Animal Parts',
      filePath: '../../../../assets/order/warning-label/Animal_Parts.svg',
      text: ''
    },
    {
      name: 'Indigo Garment',
      filePath: '../../../../assets/order/warning-label/Indigo_Garment.svg',
      text: ''
    },
    {
      name: 'LCA-99-NONS-013',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-013.svg',
      text: 'This product contains\nXX% Organic Cotton Standard\ncertified cotton\nCertified by Control Union\nCU1034687'
    },
    {
      name: 'LCA-99-NONS-015',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-015.svg',
      text: 'This product contains\nXX% Organic Cotton Standard\ncertified cotton\nCertified by Control Union\nCU1034687'
    },
    {
      name: 'LCA-99-NONS-016',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-016.svg',
      text: 'Made with XX% Organic Cotton\nCertified by Control Union\nCertificate nr: 1034687'
    },
    {
      name: 'LCA-99-NONS-017',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-017.svg',
      text: '’Made with XX%\norganic cotton’\nCertified by Control Union\nCU 1034687'
    },
    {
      name: 'LCA-99-NONS-018',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-018.svg',
      text: 'This product contains\nXX% GRS certified\nRecycled Wool\nCertified by Control Union\nCU1034687'
    },
    {
      name: 'LCA-99-NONS-019',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-019.svg',
      text: 'This product contains\nXX% RWS certified wool\ncertified by Control Union\nCU 880804'
    },
    {
      name: 'LCA-99-NONS-020',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-020.svg',
      text: 'Made with XX% Recycled Polyester\nCertified by Control Union\nCertificate nr: 1034687'
    },
    {
      name: 'LCA-99-NONS-021',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-021.svg',
      text: 'Made with XX% Recycled Polyester\nCertified by Control Union\nCertificate nr: 1034687'
    },
    {
      name: 'LCA-99-NONS-025',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-025.svg',
      text: ''
    },
    {
      name: 'LCA-99-NONS-026',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-026.svg',
      text: ''
    },
    {
      name: 'LCA-99-NONS-027',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-027.svg',
      text: ''
    },
    {
      name: 'LCA-99-NONS-028',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-028.svg',
      text: ''
    },
    {
      name: 'LCA-99-NONS-029',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-029.svg',
      text: ''
    },
    {
      name: 'LCA-99-NONS-030',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-030.svg',
      text: ''
    },
    {
      name: 'LCA-99-NONS-031',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-031.svg',
      text: ''
    },
    {
      name: 'LCA-99-NONS-034',
      filePath: '../../../../assets/order/warning-label/LCA-99-NONS-034.svg',
      text: 'Made with XX% recycled wool\nand XX% recycled polyamide\nCertified by Control Union\nCU 1034687'
    },
    {
      name: 'Recycle',
      filePath: '../../../../assets/order/warning-label/Recycle.svg',
      text: ''
    },*/

  ]

  selectedWarningLabel: any[] = [];

  warningLabelList = new FormControl([]);

  scale = [1.0, 2.0, 3.0]

  selectedScale = 2.0

  originLeftMargin = 1 * this.selectedScale;
  originRightMargin = 1 * this.selectedScale;
  originTextSize = 6 * this.selectedScale;

  fabricLeftMargin = 1 * this.selectedScale;
  fabricRightMargin = 1 * this.selectedScale;
  fabricTextSize = 6 * this.selectedScale;

  instructionLeftMargin = 1 * this.selectedScale;
  instructionRightMargin = 1 * this.selectedScale;
  instructionTextSize = 5.5 * this.selectedScale;

  russianDate = '';
  russianType: any = {
    name: '',
    value: ''
  };
  isRussianTypeSelected = false;
  isRussianActive = false;
  russianQuantity = 0;

  id: string = '';

  displayFillDialog = false;
  text = '';
  totalChinese = 0;

  quantity = 0;

  productCategories: string[] = ['', 'RN# 100759'];
  productGroupTypes: string[] = ['Men + Unisex + Boys + Girls', 'Women'];

  groupTypeAddress = {
    'Men + Unisex + Boys + Girls': 'United Legwear & Apparel Europe B.V.<br>Jacobus Spijkerdreef 20<br>2132 PZ Hoofddorp<br>The Netherlands',
    'Women': 'Mamiye Europe B.V.<br>Jacobus Spijkerdreef 20<br>2132 PZ Hoofddorp<br>The Netherlands',
    'Default': 'Scotch & Soda B.V.,<br>Jacobus Spijkerdreef 20,<br>2132 PZ, Hoofddorp,<br>the Netherlands'
  }

  productCategory = '';
  productGroupType = this.productGroupTypes[0];

  website: string = 'scotchandsoda.com';

  constructor(
    public dialog: MatDialog,
    private originCountryService: OriginCountryService,
    private service: WashingInstructionService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private warningLabelService: WarningLabelService
  ) { 

    

  }

  ngOnInit(): void {

    this.warningLabelService.getAllWarningLabels().subscribe(res => {
      this.warningLabels = res
    })

    this.route.queryParams.subscribe(params => {
      console.log(params)
      this.id = params.no
    })

    if (this.id) {

      this.service.getById(this.id).subscribe((res: any) => {

        console.log(res)
        this.bgColor = res.fabricColor;
        this.warningLabelList = new FormControl(res.warningLabelList);
        //this.selectedWarningLabel = res.warningLabel;
        this.originObj = res.originCountry;
        if(!this.originObj.value['sa']){
          this.originLanguages.pop()
          this.fabricLanguages.pop()
        }
        if(!this.originObj.value['il']){
          this.originLanguages.pop()
          this.fabricLanguages.pop()
        }
        if(!this.originObj.value['ua']){
          this.originLanguages.pop()
          this.fabricLanguages.pop()
          //this.website = 'scotch-soda.com'
        }
        this.chineseOrigin = this.originObj.value.ch
        this.poNumber = res.poNumber;
        this.symbolsShown = res.washingSymbols;
        this.chineseSizes = res.chineseSizes;
        for(let size of this.chineseSizes){
          this.totalChinese = this.totalChinese + parseInt(size.quantity);
        }
        this.fabricOptions = res.productPartModels;
        this.instructions = res.washingInstructions;
        this.russianDate = res.russianLabelModel.russianModelDate;
        this.russianType = res.russianLabelModel.russianProductType;
        this.isRussianActive = res.russianLabelModel.isActive;
        this.russianQuantity = res.russianLabelModel.quantity;
        this.quantity = res.quantity ? res.quantity : 0;
        this.productCategory = res.productCategory ? res.productCategory : '';
        this.productGroupType = res.productGroupType ? res.productGroupType : 'Default';

        /*if(res.productGroupType && res.productGroupType !== 'Default'){
          this.originLanguages.push('ru')
        }*/

        console.log(this.russianType.value)
        if (this.russianType.value !== '') {
          this.isRussianTypeSelected = true;
        }
        this.rusFabricOptions = res.russianProductPartModels ? res.russianProductPartModels : []
        this.chineseFabricOptions = res.chineseProductPartModels ? res.chineseProductPartModels : []
        this.isOriginSelected = true;
        this.isFabricSelected = true;
        this.isInstructionsSelected = true

        this.originLanguages.forEach(lang => {
          this.originValue = this.originValue + ' / ' + this.originObj.value[lang];
        })
        this.originValue = this.originValue.slice(2, this.originValue.length)

        this.origin = this.originObj.countryName
        this.serial = this.poNumber.split('-')[0];

        this.changeFabricTextSize()
        this.changeInstructionTextSize()

      })
    }

  }

  ngAfterViewInit() {

    var height = this.fabricDiv.nativeElement.offsetHeight;

    console.log('Height: ' + height);
  }

  /*onCategoryClicked() {

    const dialogRef = this.dialog.open(ProductCategoryDialogComponent, {
      data: this.productCategory
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      this.productCategory = result ? result : '';
      
    })

  }*/

  onOriginClicked() {
    const dialogRef = this.dialog.open(OriginCountryDialogComponent, {
      data:
      {
        origin: this.origin,
        //leftMargin: this.originLeftMargin / this.selectedScale,
        //rightMargin: this.originRightMargin / this.selectedScale,
        textSize: this.originTextSize / this.selectedScale
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.origin = result.origin
      //this.originRightMargin = result.rightMargin * this.selectedScale;
      //this.originLeftMargin = result.leftMargin * this.selectedScale;
      this.originTextSize = result.textSize * this.selectedScale;

      this.isOriginSelected = true;

      this.originCountryService.getByCountryName(this.origin).subscribe(res => {
        this.originValue = ''
        this.originObj = res
        this.originLanguages.forEach(lang => {
          this.originValue = this.originValue + ' / ' + this.originObj.value[lang];
        })
        this.chineseOrigin = this.originObj.value.ch
        this.originValue = this.originValue.slice(2, this.originValue.length)
        console.log(this.originValue)
      })
      /*let slashIndex = this.originValue.lastIndexOf('/')
      this.chineseOrigin = this.originValue.substring(slashIndex + 1, this.originValue.length)
      this.originValue = this.originValue.substring(0, slashIndex)
      let rusSlashIndex = this.originValue.lastIndexOf('/')
      this.russianOrigin = this.originValue.substring(rusSlashIndex + 1, this.originValue.length)
      this.originValue = this.originValue.substring(0, rusSlashIndex)*/
    });
  }

  onSymbolsClicked() {
    console.log(this.selectedSymbols)
    const dialogRef = this.dialog.open(SymbolDialogComponent, {
      height: '400px', width: '60%', data: this.selectedSymbols
    });

    dialogRef.afterClosed().subscribe(result => {
      this.symbolsShown = []
      this.selectedSymbols = result
      this.selectedSymbols.forEach(list => {
        list.forEach(item => {

          if (!this.symbolsShown.includes(item))
            this.symbolsShown.push(item)

        })
      })

      this.symbolsShown = this.symbolsShown.sort((n1: WarningSymbol, n2: WarningSymbol) => {
        if (n1.order > n2.order) {
          return 1;
        }

        return -1;
      })
      console.log(this.selectedSymbols)
      console.log(this.symbolsShown)
    });

  }

  onFabricOptionsClicked() {
    const dialogRef = this.dialog.open(FabricOptionsDialogComponent, {
      height: '86%', width: '60%', data: {
        normal: this.fabricOptions,
        rus: this.rusFabricOptions,
        chinese: this.chineseFabricOptions,
        //leftMargin: this.fabricLeftMargin / this.selectedScale,
        //rightMargin: this.fabricRightMargin / this.selectedScale,
        textSize: this.fabricTextSize / this.selectedScale
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result.normal.length > 0) {
        this.isFabricSelected = true
        this.fabricOptions = result.normal;
        this.rusFabricOptions = result.rus;
        this.chineseFabricOptions = result.chinese;

        //this.fabricLeftMargin = result.leftMargin * this.selectedScale;
        //this.fabricRightMargin = result.rightMargin * this.selectedScale;
        this.fabricTextSize = result.textSize * this.selectedScale;

      }
      else
        this.isFabricSelected = false

      this.changeFabricTextSize()

    });

  }

  changeFabricTextSize(){
    this.changeDetectorRef.detectChanges();
    var height = this.fabricDiv.nativeElement.offsetHeight;
    console.log('Height: ' + height);

    if (height > 500) {
      this.fabricTextSize = this.fabricTextSize - 1
    } else if (height < 300 && this.fabricTextSize / this.selectedScale < 6) {
      this.fabricTextSize = this.fabricTextSize + 1
    }
    else{
      return;
    }
    this.changeFabricTextSize()

  }

  onWashingInstructionsClicked() {
    const dialogRef = this.dialog.open(WashingInstructionsDialogComponent, {
      height: '440px', width: '80%', data: {
        instructions: this.instructions,
        //leftMargin: this.instructionLeftMargin / this.selectedScale,
        //rightMargin: this.instructionRightMargin / this.selectedScale,
        //textSize: this.instructionTextSize / this.selectedScale
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      this.instructions = result.instructions;
      if (result.instructions.length > 0) {
        this.isInstructionsSelected = true;
        //this.instructionLeftMargin = result.leftMargin * this.selectedScale;
        //this.instructionRightMargin = result.rightMargin * this.selectedScale;
        //this.instructionTextSize = result.textSize * this.selectedScale;
      }
      else
        this.isInstructionsSelected = false;
      console.log(this.instructions)
      this.changeInstructionTextSize()
    });

  }

  changeInstructionTextSize(){
    this.changeDetectorRef.detectChanges();
    var height = this.instructionDiv.nativeElement.offsetHeight;
    console.log('Height: ' + height);

    if (height > 500) {
      this.instructionTextSize = this.instructionTextSize - 1
    } else if (height < 300 && this.instructionTextSize / this.selectedScale < 5.5) {
      this.instructionTextSize = this.instructionTextSize + 1
    }
    else{
      return;
    }
    this.changeInstructionTextSize()

  }

  onRussianTypeClicked() {

    const dialogRef = this.dialog.open(RussianTypeDialogComponent, {
      data: this.russianType
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result)
        this.isRussianTypeSelected = true
      this.russianType = result;
      console.log(this.russianType)
    })

  }

  setTextColor() {
    this.textColor = this.bgColor === '#000000' ? '#fff' : '#000'
  }

  poNumberChanged() {
    this.serial = this.poNumber.split('-')[0];
  }

  selectChineseSize() {

    const dialogRef = this.dialog.open(ChineseLabelDialogComponent, {
      height: '440px', width: '100%', data: { textSize: 8, list: this.instructions }
    });

    dialogRef.afterClosed().subscribe(result => {
      let chineseQuantity: any[] = []
      this.totalChinese = 0;
      result.forEach((item: any) => {

        this.totalChinese = this.totalChinese + parseInt(item.quantity)
        chineseQuantity.push(
          {
            size: item,
            quantity: item.quantity
          }
        )

      })
      this.chineseSizes = chineseQuantity;
      console.log(this.chineseSizes)
    });

  }

  controlInstruction(){

    this.text = ''
    let isFilled = true;
    if(!this.isOriginSelected){
      this.text = this.text + '- Please Select the Origin Country!\n';
      isFilled = false;
    }
    if(this.poNumber.length == 0){
      this.text = this.text + '- Please Provide the PO Number!\n';
      isFilled = false;
    }
    if(this.symbolsShown.length == 0){
      this.text = this.text + '- Please Select Washing Symbols!\n';
      isFilled = false
    }
    if(!this.isFabricSelected){
      this.text = this.text + '- Please Select the Fabric Options!\n';
      isFilled = false;
    }
    if(!this.isInstructionsSelected){
      this.text = this.text + '- Please Select the Instructions!\n';
      isFilled = false;
    }

    if(isFilled){
      this.confirmSaveInstructionDialog();
    }
    else{
      this.displayFillDialog = true;
    }
  }

  hideControlDialog(){
    this.text = '';
    this.displayFillDialog = false;
  }


  confirmSaveInstructionDialog(){

    this.confirmationService.confirm({
      message: 'Are you sure to save this instruction?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
         this.saveInstruction();
      },
      reject: (type:any) => {
          switch(type) {
              case ConfirmEventType.REJECT:
                  this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
              break;
              case ConfirmEventType.CANCEL:
                  this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
              break;
          }
      }
  });
  }

  saveInstruction() {



    let instruction = {

      id: this.id,
      fabricColor: this.bgColor,
      warningLabelList: this.warningLabelList.value,
      originCountry: this.originObj,
      poNumber: this.poNumber,
      washingSymbols: this.symbolsShown,
      chineseSizes: this.chineseSizes,
      productPartModels: this.fabricOptions,
      russianProductPartModels: this.rusFabricOptions,
      chineseProductPartModels: this.chineseFabricOptions,
      washingInstructions: this.instructions,
      quantity: this.quantity,
      productCategory : this.productCategory,
      productGroupType: this.productGroupType,
      russianLabelModel: {
        russianModelDate: this.russianDate,
        russianProductType: this.russianType,
        isActive: this.isRussianActive,
        quantity: this.russianQuantity
      }
      
    }

    console.log(instruction)

    this.service.save(instruction).subscribe(res => {


      this.bgColor='#fff';
      this.selectedWarningLabel=[];
      this.warningLabelList = new FormControl([]);
      this.originObj={
        value: {
          gb: '',
          ru: '',
          ch: ''
        }
      }
      this.poNumber='';
      this.symbolsShown=[];
      this.chineseSizes=[];
      this.fabricOptions=[];
      this.rusFabricOptions=[];
      this.chineseFabricOptions=[];
      this.instructions=[];
      this.russianDate='';
      this.russianType={
        name: '',
        value: ''
      };
      this.quantity = 0;
      this.productCategory = '';
      this.productGroupType = this.productGroupTypes[0];

      this.isOriginSelected = false;
      this.totalChinese = 0;
      this.isFabricSelected = false
      this.isInstructionsSelected = false
      this.isRussianTypeSelected = false;
      this.isRussianActive = false;
      this.russianQuantity = 0;
    })

  }

  scaleView() {
    return 'scale(' + this.selectedScale / 2 + ')';
  }

}
