<div class="example-sidenav-content">
    <div class="p-3">
        <p-table #dt styleClass="p-datatable-customers" [value]="listInstructions"
            [globalFilterFields]="['creator.company','creator.firstName','poNumber','createDate']" [rows]="10"
            [rowsPerPageOptions]="[10,25,50]" [rowHover]="true" [paginator]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
            <ng-template pTemplate="caption">
                <div class="table-header">
                    <div class="row" style="width: 100%;">
                        <div class="col-md-8">
                            WASHING INSTRUCTIONS LIST
                        </div>
                        <div class="col-md-3">
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search" />
                        </div>
                        <div class="col-md-1">
                            <button mat-raised-button (click)="openCreate()" color="primary">Create</button>
                        </div>
                    </div>


                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [style]="{'width':'16%'}" pSortableColumn="creator.company">Firma Adı <p-sortIcon
                            field="creator.company">
                        </p-sortIcon>
                    </th>
                    <th [style]="{'width':'17%'}" pSortableColumn="creator.firstName">Name Surname <p-sortIcon
                            field="creator.firstName">
                        </p-sortIcon>
                    </th>
                    <th [style]="{'width':'15%'}" pSortableColumn="poNumber">Po Number <p-sortIcon field="poNumber">
                        </p-sortIcon>
                    </th>
                    <th [style]="{'width':'15%'}" pSortableColumn="createDate">Create Date <p-sortIcon
                            field="createDate">
                        </p-sortIcon>
                    </th>
                    <!--<th  [style]="{'width':'15%'}" pSortableColumn="userRole">Notes <p-sortIcon field="userRole"></p-sortIcon>
          </th>-->
                    <th>
                    </th>
                    <th>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-list>
                <tr>
                    <td>{{list?.creator.company}}</td>
                    <td>{{list?.creator.firstName}} {{list?.creator.lastName}}</td>
                    <td>{{list?.poNumber}}</td>
                    <td>{{list?.createDate | date: 'dd/MM/yyyy'}}</td>
                    <td><a style="text-decoration:none;color:black;cursor:pointer;font-weight: 600;"
                            [ngStyle]="{'color': list.criticsModels.length > 0 ? 'red' : 'black'}"
                            (click)="openNoteDialog(list)">See
                            Critics</a> </td>
                    <td><button pButton pRipple icon="pi pi-eye" (click)="openShowDialog(list)"
                            class="p-button-rounded p-button-secondary p-button-text"></button></td>
                    <td><button pButton pRipple icon="pi pi-thumbs-down" style="color: red"
                            class="p-button-rounded p-button-secondary p-button-text"
                            (click)="updateStatusDialog(list)"></button>
                    </td>
                    <td><button pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-secondary p-button-text"
                            (click)="openModalDialog(list)"></button></td>
                    <td><button pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-secondary p-button-text"
                            (click)="openDeleteDialog(list)"></button></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">No Washing instructions found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<!-- edit  dialog -->
<p-dialog [(visible)]="displayDialog" [style]="{'width':'45%'}">
    <ng-template pTemplate="header">
        YIKAMA TALİMATLARINI DÜZENLE
    </ng-template>
    <div class="p-fluid p-grid">
        <div class="row">
            <!-- name input -->
            <div class="field col-12 input-class">
                <label>Ad</label>
                <input class="form-control" id="name" aria-describedby="emailHelp"
                    [(ngModel)]="selectedInstruction.creator.firstName">
            </div>
            <!-- surname input -->
            <div class="field col-12 input-class">
                <label>Soyad</label>
                <input class="form-control" id="surname" aria-describedby="emailHelp"
                    [(ngModel)]="selectedInstruction.creator.lastName">
            </div>
        </div>
        <div class="row">
            <!-- company input -->
            <div class="field col-12 input-class">
                <label>Şirket Adı</label>
                <input class="form-control" id="companyName" aria-describedby="emailHelp"
                    [(ngModel)]="selectedInstruction.creator.company">
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button label="Kaydet" (click)="editUser()"></p-button>
        <button pButton pRipple type="button" label="İptal" class="p-button-raised p-button-text p-button-plain"
            (click)="hideDialog()"></button>
    </ng-template>
</p-dialog>

<!-- delete instruction list dialog -->
<p-dialog [(visible)]="displayShowDialog" [style]="{'width':'60%', 'margin-top': '4%', 'height': '86%'}">
    <div>
        <div style="color: #000000; padding-left: 1%;">
            <div *ngIf="selectedInstruction.criticsModels.length > 0"
                style="background-color: #eee; width: 90%; padding: 1%; border-radius: 10px;">
                <div><strong>CRITICS</strong></div>
                <div *ngFor="let item of selectedInstruction.criticsModels" class="row">
                    <div class="col-md-6">{{item.critic}}</div>
                    <div class="col-md-3">{{item.date | date}}</div>
                    <div class="col-md-3">{{item.controller.firstName}} {{item.controller.lastName}}</div>
                </div>

            </div>
            <div #pdf>
                <div class="row">
                    <div class="instruction" [ngStyle]="{'background-color': selectedInstruction.fabricColor}">
                        <div
                            [ngStyle]="{'filter': selectedInstruction.fabricColor === '#000000' ? 'invert(1)' : 'invert(0)'}">
                            <div class="label-number"></div>
                            <div class="scissor row">
                                <img id="scissor-img" src="../../../../assets/order/cutline.svg" />
                            </div>
                            <div class="origin">
                                <div
                                    [ngStyle]="{'margin-right': '2mm', 'margin-left': '2mm', 'font-size':'12pt'}">
                                    {{selectedInstruction.productCategory}}
                                </div>
                            </div>
                            <div class="origin">
                                <div [ngStyle]="{'margin-right': '2mm', 'margin-left': '2mm', 'font-size':'12pt'}">
                                    <span *ngFor="let lang of originLanguages"><br *ngIf="lang == 'sa'">{{selectedInstruction.originCountry.value[lang]}}<span *ngIf="lang !== 'id' && (selectedInstruction.originCountry.value['sa'] || lang !== 'il')"> / </span></span>
                                </div>
                            </div>
                            <div id="po-number" [ngStyle]="{'margin-top': selectedInstruction.productCategory.length > 0 ? '1cm': '1.5cm'}">
                                <div style="text-align:center; width: 100%">{{selectedInstruction.poNumber}}</div>
                            </div>
                            <div class="symbols row origin">
                                <div *ngFor="let symbol of selectedInstruction.washingSymbols">
                                    <img class="symbol-img" src="{{symbol.filePath}}" />
                                </div>
                            </div>
                            <div class="clevercare-logo">
                                <img id="logo-image" src="../../../../../assets/order/clever-black.png" />
                            </div>
                            <div *ngIf="!selectedInstruction.productGroupType || selectedInstruction.productGroupType == 'Default'" class="address">
                                Scotch & Soda B.V. Keizersgracht 105 1015CH Amsterdam The Netherlands scotch-soda.com
                            </div>
                            <div *ngIf="selectedInstruction.productGroupType && selectedInstruction.productGroupType !== 'Default'" class="address">
                                Scotch & Soda Keizersgracht 22 1015 CR Amsterdam The Netherlands {{website}}
                            </div>
                        </div>
                    </div>
                    <div class="instruction fabric-ratio"
                        [ngStyle]="{'background-color': selectedInstruction.fabricColor}">
                        <div
                            [ngStyle]="{'filter': selectedInstruction.fabricColor === '#000000' ? 'invert(1)' : 'invert(0)'}">
                            <div class="fabric" #fabricDiv
                                [ngStyle]="{'margin-right': '0mm', 'margin-left': '0mm', 'font-size':fabricTextSize+'pt', 'line-height':fabricTextSize+'pt'}">
                                <div style="margin-top: 1%;">
                                    <div *ngFor="let lang of fabricLanguages" class="fabric-row row">
                                        <div style="width: 16%; padding-left: 8%; margin-top: -1%;">
                                            {{lang}}
                                        </div>
                                        <div style="width: 84%; ">
                                            <div *ngFor="let fabric of selectedInstruction.productPartModels"
                                                style="margin-left: 6%; width: 100%;"
                                                [ngStyle]="{'line-height': fabricTextSize+'pt'}">
                                                {{fabric.productPart.value[lang.toLowerCase()]}}&nbsp;
                                                <span *ngFor="let feature of fabric.features" style="width: 100%;">
                                                    {{feature.ratio}}%&nbsp;{{feature.value.value[lang.toLowerCase()]}}&nbsp;
                                                </span>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="instruction" [ngStyle]="{'background-color': selectedInstruction.fabricColor}">
                        <div
                            [ngStyle]="{'filter': selectedInstruction.fabricColor === '#000000' ? 'invert(1)' : 'invert(0)'}">

                            <div class="cut-margin"></div>
                            <div class="label-number">{{selectedInstruction.poNumber.split('-')[0]}}</div>

                            <div class="scissor row">
                                <img id="scissor-img" src="../../../../assets/order/cutline.svg" />
                            </div>
                            <div class="fabric" style="padding-left: 2mm; overflow: hidden;" #instructionDiv
                                [ngStyle]="{'margin-right': '0mm', 'margin-left': '0mm', 'font-size':instructionTextSize+'pt'}">
                                <div>
                                    <div *ngFor="let lang of instructionLanguages" class="fabric-row row"
                                        style="margin-top: 0%; padding-top: 0%;">
                                        <div style="margin-top: -1%; width: 16%; padding-left: 6%;">
                                            {{lang}}
                                        </div>
                                        <div style="width: 84%; margin-top: 1%;">
                                            <div *ngFor="let instruction of selectedInstruction.washingInstructions"
                                                [ngStyle]="{'lineHeight': (instructionTextSize)+'pt'}"
                                                style="margin-left: 6%; margin-top: 0%;">
                                                {{instruction.value[lang.toLowerCase()]}}&nbsp;

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="instruction" *ngFor="let size of selectedInstruction.chineseSizes"
                        [ngStyle]="{'background-color': selectedInstruction.fabricColor}">
                        <div
                            [ngStyle]="{'filter': selectedInstruction.fabricColor === '#000000' ? 'invert(1)' : 'invert(0)'}">

                            <div class="cut-margin"></div>
                            <div class="row">
                                <div class="col-md-8"></div>
                                <div class="col-md-4 label-number" style="text-align:right; padding-right: 0%;">
                                    {{size.size.normal}}</div>

                            </div>

                            <div class="scissor row">
                                <img id="scissor-img" src="../../../../assets/order/cutline.svg" />
                            </div>
                            <div class="chinese-label-info">
                                <div>
                                    品牌:
                                </div>
                                <div>
                                    <img src="../../../../assets/order/scotch_soda_logo.svg" style="width: 144px;" />
                                </div>
                            </div>
                            <div class="chinese-label-info">
                                <div>
                                    款号:
                                </div>
                                <div style="line-height: 12pt">
                                    {{selectedInstruction.poNumber}}
                                </div>
                            </div>
                            <div class="chinese-label-info">
                                <div style="line-height: 12pt">
                                    号型 / 规格:
                                </div>
                                <div>
                                    {{size.size.chinese}}
                                </div>
                            </div>
                            <div class="chinese-label-info">
                                <div style="line-height: 12pt">
                                    产地: {{selectedInstruction.originCountry.value.ch}}
                                </div>
                            </div>
                            <div class="chinese-label-info">
                                <div style="line-height: 12pt">
                                    成分:
                                </div>
                                <div *ngIf="selectedInstruction.chineseProductPartModels.length == 0">
                                    <div *ngFor="let fabric of selectedInstruction.productPartModels"
                                        style="line-height: 12pt;">
                                        {{fabric.productPart.value.ch}}:&nbsp;
                                        <span *ngFor="let feature of fabric.features">
                                            {{feature.ratio}}%&nbsp;{{feature.value.value.ch}}&nbsp;
                                        </span>

                                    </div>
                                </div>
                                <div *ngIf="selectedInstruction.chineseProductPartModels.length > 0">
                                    <div *ngFor="let fabric of selectedInstruction.chineseProductPartModels"
                                        style="line-height: 12pt;">
                                        {{fabric.productPart.value.ch}}:&nbsp;
                                        <span *ngFor="let feature of fabric.features">
                                            {{feature.ratio}}%&nbsp;{{feature.value.value.ch}}&nbsp;
                                        </span>

                                    </div>
                                </div>
                                <!--<div *ngFor="let fabric of selectedInstruction.productPartModels" style="line-height: 12pt;">
                        {{fabric.productPart.value.ch}}:&nbsp;
                        <span *ngFor="let feature of fabric.features">
                            {{feature.ratio}}%&nbsp;{{feature.value.value.ch}}&nbsp;
                        </span>

                    </div>-->
                            </div>
                            <div>
                                <div class="row" style="padding: 4%; margin-top: 4%;">
                                    <div style="font-size: 10pt; padding-right: 0%; width: 20%; text-align: right;">
                                        中文:
                                    </div>
                                    <div style="width: 80%;">
                                        <div *ngFor="let instruction of selectedInstruction.washingInstructions"
                                            style="font-size: 10pt;  line-height: 13pt;">
                                            &nbsp;&nbsp;{{instruction.value.ch}}&nbsp;

                                        </div>

                                    </div>

                                </div>
                                <div class="chineseWashing">
                                    以本洗水标信息为准
                                </div>
                                <div class="symbols row origin">
                                    <div *ngFor="let symbol of selectedInstruction.washingSymbols">
                                        <img class="symbol-img" src="{{symbol.filePath}}" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="selectedInstruction.russianLabelModel.isActive" class="instruction rus-date"
                        [ngStyle]="{'background-color': selectedInstruction.fabricColor}">
                        <div
                            [ngStyle]="{'filter': selectedInstruction.fabricColor === '#000000' ? 'invert(1)' : 'invert(0)'}">
                            <div class="cut-margin"></div>
                            <div class="label-number">{{selectedInstruction.poNumber.split('-')[0]}}</div>
                            <div class="scissor row">
                                <img id="scissor-img" src="../../../../assets/order/cutline.svg" />
                            </div>

                            <div style="margin-top: 24%;">Дата производства</div>
                            <div style="font-size: 12pt; text-align:center; width: 100%; margin-bottom: 10%;">
                                {{selectedInstruction.russianLabelModel.russianModelDate}}
                            </div>
                            <div>Наименование товара</div>
                            <div style="text-align: center;">
                                {{selectedInstruction.russianLabelModel.russianProductType.value}}
                            </div>
                            <div
                                style="margin-bottom: 10%; margin-top: 1cm; width: 100%; text-align: center; padding: 10%;">
                                {{selectedInstruction.originCountry.value.ru}}</div>
                            <div *ngIf="selectedInstruction.russianProductPartModels.length > 0">
                                <div *ngFor="let fabric of selectedInstruction.russianProductPartModels"
                                    style="width: 100%; text-align: center; padding: 10%; padding-top: 6%;"
                                    [ngStyle]="{'line-height':'12pt'}">
                                    {{fabric.productPart.value['ru']}}&nbsp;
                                    <span *ngFor="let feature of fabric.features">
                                        {{feature.ratio}}%&nbsp;{{feature.value.value['ru']}}&nbsp;
                                    </span>

                                </div>
                            </div>

                            <div *ngIf="selectedInstruction.russianProductPartModels.length == 0">
                                <div *ngFor="let fabric of selectedInstruction.productPartModels"
                                    style="width: 100%; text-align: center;  padding-left: 10%; padding-right: 10%;"
                                    [ngStyle]="{'line-height':'12pt'}">
                                    {{fabric.productPart.value['ru']}}&nbsp;
                                    <span *ngFor="let feature of fabric.features">
                                        {{feature.ratio}}%&nbsp;{{feature.value.value['ru']}}&nbsp;
                                    </span>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div *ngFor="let warningLabel of selectedInstruction.warningLabelList">
                        <div style="margin: 10px;"
                            [ngStyle]="{'background-color': (warningLabel.name === 'Animal Parts' || warningLabel.name === 'Indigo Garment') ? selectedInstruction.fabricColor : '#FFF'}">
                            <div
                                [ngStyle]="{'filter': selectedInstruction.fabricColor === '#000000' && (warningLabel.name === 'Animal Parts' || warningLabel.name === 'Indigo Garment') ? 'invert(1)' : 'invert(0)'}">
                                <img style="height: 160mm;" src="{{warningLabel.filePath}}" />
                                <div
                                    style="position: absolute; top: 100mm; left: 1mm; right: 1mm; text-align: center; width: 68mm;">
                                    <span style="font-size: 12pt; word-break:keep-all;"
                                        [innerHtml]="transformText(warningLabel.text)"></span>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="instruction info" [ngStyle]="{'background-color': selectedInstruction.fabricColor}">
                        <div
                            [ngStyle]="{'filter': selectedInstruction.fabricColor === '#000000' ? 'invert(1)' : 'invert(0)'}">

                            <div class="cut-margin"></div>
                            <div class="label-number"></div>
                            <div class="scissor row">
                                <img id="scissor-img" src="../../../../assets/order/cutline.svg" />
                            </div>
                            <br *ngIf="!(!selectedInstruction.originCountry.value['il'])">
                            <strong>
                                Trademark and<br>
                                Brand Name of the product.
                            </strong>
                            <p>
                                SCOTCH & SODA
                            </p>
                            <br *ngIf="!(!selectedInstruction.originCountry.value['il'])">
                            <strong>
                                Name of the Exporter
                            </strong>
                            <p *ngIf="selectedInstruction.productGroupType" [innerHTML]="groupTypeAddress[selectedInstruction.productGroupType]">
                               
                            </p>
                            <p *ngIf="!selectedInstruction.productGroupType">
                                Scotch & Soda B.V.,<br>
                                Jacobus Spijkerdreef 20,<br>
                                2132 PZ, Hoofddorp,<br>
                                the Netherlands
                            </p>
                            <br *ngIf="!(!selectedInstruction.originCountry.value['il'])">
                            <strong>
                                Name of the Importer
                            </strong>
                            <p>
                                Beside Trading Company, 5,<br>
                                Abu Bakr Els-Seddik Street,<br>
                                Sheraton Heleopolis<br>
                                Square 1144, Cairo,<br>
                                EGYPT
                            </p>
                            <br *ngIf="!(!selectedInstruction.originCountry.value['il'])">
                            <strong>
                                UK representative
                            </strong>
                            <p>
                                14 Carnaby St, Carnaby<br>
                                London W1F 9PW<br>
                                United Kingdom
                            </p>
                            <strong *ngIf="!selectedInstruction.originCountry.value['il']">
                                Импортер
                            </strong>
                            <p *ngIf="!selectedInstruction.originCountry.value['il']">
                                AO “MФК ДжамильКо”<br>
                                107031, г. Mocква,<br>
                                Tрубная площадь, д.2,<br>
                                эт.1,пом.I, ком.55
                            </p>
                            <img *ngIf="!selectedInstruction.originCountry.value['il']" id="eac" src="../../../../assets/eac.svg" width="60">
                        </div>
                    </div>
                    <div style="margin: 10px; height: 160mm;"
                        [ngStyle]="{'background-color': selectedInstruction.fabricColor}">
                        <div
                            [ngStyle]="{'filter': selectedInstruction.fabricColor === '#000000' ? 'invert(1)' : 'invert(0)'}">
                            <img style="height: 160mm;" src="../../../../assets/order/warning-label/Recycle.svg" />

                        </div>

                    </div>
                    <div style="background-color: #dfdfdf; width: 6cm; border-radius: 10px; margin: 10px; padding: 2%;">
                        <div *ngIf="selectedInstruction.chineseSizes.length > 0" style="margin: 4%">
                            <strong>CHINESE:</strong>
                        </div>
                        <div *ngFor="let size of selectedInstruction.chineseSizes"
                            style="font-size: 12pt; margin-top: 16%; margin: 6%;">
                            <strong>{{size.size.normal}}:</strong> {{size.quantity}}
    
                        </div>
                        <div *ngIf="selectedInstruction.chineseSizes.length > 0" style="margin: 6%"><strong>Total:
                            </strong>{{totalChinese}}
                        </div>
                        <div *ngIf="selectedInstruction.russianLabelModel.isActive" style="margin: 4%">
                            <strong>RUSSIAN:</strong>
                        </div>
                        <div *ngIf="selectedInstruction.russianLabelModel.isActive" style="margin: 6%"><strong>Quantity:
                            </strong>{{selectedInstruction.russianLabelModel.quantity}} </div>
                        <div style="margin: 4%"><strong>TOTAL QUANTITY:</strong></div>
                        <div style="margin: 6%">{{selectedInstruction.quantity}}</div>
                        <div style="margin: 4%"><strong>{{selectedInstruction.creator.company}}</strong></div>
    
                    </div>



                </div>
            </div>

        </div>
    </div>
    <div class="row">
        <button style="margin: 2%;" class="btn btn-primary" (click)="pdfExport()">EXPORT TO PDF</button>
        <button style="margin: 2%;" class="btn btn-secondary" (click)="print()">PRINT</button>

    </div>

</p-dialog>

<!-- add note dialog -->
<p-dialog [(visible)]="displayAddNoteDialog" [style]="{'width':'60%'}">
    <ng-template pTemplate="header">
        KRİTİKLER
    </ng-template>

    <div *ngFor="let item of selectedInstruction.criticsModels" class="row">
        <div class="col-md-6">{{item.critic}}</div>
        <div class="col-md-3">{{item.date | date}}</div>
        <div class="col-md-3">{{item.controller.firstName}} {{item.controller.lastName}}</div>
    </div>
    <div class="row mx-auto">
        <textarea *ngIf="userRole == 'CONTROLLER'" rows="4" class="col-12 form-control mx-auto"
            [(ngModel)]="this.noteText" placeholder="Add Critics"></textarea>
    </div>


    <ng-template pTemplate="footer">
        <p-button *ngIf="userRole == 'CONTROLLER'" label="Add Critic" (click)="sendNote()"></p-button>
        <button pButton pRipple type="button" label="Close" class="p-button-raised p-button-text p-button-plain"
            (click)="hideNoteDialog()"></button>
    </ng-template>
</p-dialog>




<!--  update status confirm dialog -->
<p-confirmDialog acceptLabel="Tamam" [position]="'center'" rejectLabel="Cancel" rejectButtonStyleClass="p-button-danger"
    header="Confirmation" icon="pi pi-exclamation-triangle">

</p-confirmDialog>

<p-dialog [(visible)]="displayConfirmationDialog" [style]="{'width':'45%'}">
    <ng-template pTemplate="header">
        DELETE INSTRUCTION
    </ng-template>
    <div class="p-fluid p-grid">
        <div class="row">
            <!-- name input -->
            Are You Sure To Delete This Instruction?
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button label="Delete" (click)="deleteInstruction()"></p-button>
        <button pButton pRipple type="button" label="Cancel" class="p-button-raised p-button-text p-button-plain"
            (click)="hideDeleteDialog()"></button>
    </ng-template>
</p-dialog>